<template>
  <div class="page-container attendances-page">
    <portal to="page-name">Atendimento</portal>
    <div class="main-page-content">
      <div class="card card-page">
        <div class="card-header">
          <div class="columns">
            <div class="column form-group">
              <label class="form-radio form-inline mr-2">
                <input type="radio" name="appointment-type"
                       v-model="type" value="elective">
                <i class="form-icon"></i>Agenda eletiva
              </label>
              <label class="form-radio form-inline mr-2">
                <input type="radio" name="appointment-type"
                       v-model="type" value="report_center">
                <i class="form-icon"></i>Central de laudos
              </label>
            </div>
            <div class="column col-auto hide-sm">
              <dx-dropdown
                :items="models"
                label="name"
                @select="selectModel"
                direction="right">
                <button class="btn btn-icon btn-icon-left btn-info">
                  <fa-icon :icon="['fal', 'books']" />Meus modelos
                </button>
              </dx-dropdown>
              <dx-dropdown
                class="ml-2"
                :items="prints"
                label="name"
                @select="selectPrint"
                direction="right">
                <button class="btn btn-icon btn-icon-left btn-gray">
                  <fa-icon :icon="['fal', 'print']" />Imprimir
                </button>
              </dx-dropdown>
              <button
                v-if="hasPCSModule"
                class="ml-2 btn btn-icon btn-gray"
                @click="openConfig"
              ><fa-icon :icon="['fal', 'cog']" /></button>
            </div>
          </div>
          <div class="divider" />
        </div>
        <div class="card-body">
          <exam-content-page v-if="type === 'report_center'"/>
          <hospitalized-content-page
            :patient-id="patientId"
            v-else-if="type === 'hospitalized'"/>
          <clinical-content-page v-else-if="type === 'elective'"/>
          <surgery-content-page v-else-if="type === 'surgery'"/>
        </div>
      </div>
    </div>
    <anamnesis-template-modal
      v-if="anamnesisModalShow"
      :show="anamnesisModalShow"
      @close="anamnesisModalShow = false"
    />
    <appointment-print
      v-if="appointmentPrintShow"
      :show="appointmentPrintShow"
      @close="appointmentPrintShow = false"
    />
    <billing-print
      v-if="billingPrintShow"
      :show="billingPrintShow"
      @close="billingPrintShow = false"
    />
    <document-template-modal
      v-if="documentModalShow"
      :show="documentModalShow"
      @close="documentModalShow = false"
    />
    <recipe-template-modal
      v-if="recipeModalShow"
      :show="recipeModalShow"
      @close="recipeModalShow = false"
    />
    <request-template-modal
      v-if="requestModalShow"
      :show="requestModalShow"
      @close="requestModalShow = false"
    />
    <config-modal v-if="hasPCSModule"></config-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import localforage from 'localforage';
import AnamnesisTemplateModal from '../../template/components/anamnesis/modals/Main.vue';
import AppointmentPrint from './modals/Print.vue';
import ConfigModal from './modals/Config.vue';
import BillingPrint from '../../billing/transfer/components/modals/Print.vue';
import ClinicalContentPage from './ClinicalContentPage.vue';
import DocumentTemplateModal from '../../template/components/document/modals/Main.vue';
import ExamContentPage from './ExamContentPage.vue';
import HospitalizedContentPage from './HospitalizedContentPage.vue';
import RecipeTemplateModal from '../../template/components/recipe/modals/Main.vue';
import RequestTemplateModal from '../../template/components/request/modals/Main.vue';
import SurgeryContentPage from './SurgeryContentPage.vue';

export default {
  components: {
    AnamnesisTemplateModal,
    AppointmentPrint,
    BillingPrint,
    ClinicalContentPage,
    ConfigModal,
    DocumentTemplateModal,
    ExamContentPage,
    HospitalizedContentPage,
    RecipeTemplateModal,
    RequestTemplateModal,
    SurgeryContentPage,
  },
  data() {
    return {
      type: null,
      patientId: '',
      anamnesisModalShow: false,
      appointmentPrintShow: false,
      billingPrintShow: false,
      documentModalShow: false,
      recipeModalShow: false,
      requestModalShow: false,
      models: [
        { code: 'anamnesis', name: 'Anamnese e evoluções' },
        { code: 'requests', name: 'Pedidos de exames' },
        { code: 'recipes', name: 'Receituários' },
        { code: 'documents', name: 'Impressos' },
      ],
      prints: [
        { code: 'appointments', name: 'Agendamentos' },
        { code: 'billing', name: 'Repasses' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentModule: 'currentModule',
      hasModule: 'hasModule',
    }),
    hasPCSModule() {
      return this.hasModule('pcs');
    },
  },
  watch: {
    type: function changeType() {
      return localforage.setItem('attendanceType', this.type);
    },
  },
  mounted() {
    localforage.getItem('attendanceType').then((type) => {
      if (type) {
        this.type = type;
      }
    });

    const isHospitalization = /hospitalizations/.test(this.$route.path);
    if (isHospitalization) {
      this.type = 'hospitalized';
      this.patientId = this.$route.params.patientId;
      return;
    }

    if (!this.type) {
      this.type = 'elective';
    }
  },
  methods: {
    ...mapMutations({
      openConfig: 'Attendance.OPEN_CONFIG',
    }),
    selectModel(item) {
      this.anamnesisModalShow = item.code === 'anamnesis';
      this.documentModalShow = item.code === 'documents';
      this.recipeModalShow = item.code === 'recipes';
      this.requestModalShow = item.code === 'requests';
    },
    selectPrint(item) {
      this.appointmentPrintShow = item.code === 'appointments';
      this.billingPrintShow = item.code === 'billing';
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/_variables.scss';
  @import 'src/assets/scss/mixins';

  .attendances-page {
    .menu .menu-item > a:hover {
      background: $gray-color-light;
      color: $dark-color;
    }
    .main-page-content {
      .card-body {
        padding-top: 0;
      }
    }
    .menu-type {
      float: right;
      button {
        min-width: 8rem;
      }
    }
    .forbidden-state .empty {
      border: none;
    }
  }
</style>
