<template>
  <div class="clinical-content-page">
    <template v-if="canAccess">
      <div class="columns">
        <div class="column col-auto col-md-6 col-sm-12 form-group hide-sm">
          <label class="form-label">Status</label>
          <div class="input-group">
            <dx-dropdown :items="statuses" label="name" class="input-group-addon"
                         @select="setStatus" :max-height="220">
              <span class="square c-hand" title="Status"
                    :style="{backgroundColor: statusColor}"></span>
              <template v-slot:item="{ item }">
                <div class="tile tile-centered c-hand">
                  <div class="tile-icon">
                    <span class="square" :style="{backgroundColor: item.color}"></span>
                  </div>
                  <div class="tile-content">{{ item.name }}</div>
                </div>
              </template>
            </dx-dropdown>
            <button
              class="btn btn-icon btn-neutral input-group-btn tooltip"
              data-tooltip="Informações da agenda"
              @click="information.show = true"
              :disabled="!filteredAppointments.length"
            >
              <fa-icon :icon="['fal', 'info-circle']" />
            </button>
          </div>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <label class="form-label">Agenda</label>
          <select id="schedule" class="form-select" v-model="filter.scheduleId">
            <option value="">[Todos]</option>
            <option v-for="(item, i) in filter.schedules"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <label class="form-label">Data do agendamento</label>
          <div class="show-md" :class="{loading}" v-if="loading"/>
          <st-date-nav-bar :class="loading ? 'hide-md' : ''" v-model="filter.date" />
        </div>
        <div class="column col-md-12 col-sm-12 form-group">
          <label class="form-label">Pesquisar paciente</label>
          <div class="input-group">
            <input type="text" class="form-input" v-model="search"
                   placeholder="Nome, data de nascimento ou CPF..."
                   @change="formatFilterSearch">
            <button class="btn btn-gray btn-icon btn-action input-group-btn"
                    @click="search = ''" v-if="search !== ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
            <button class="btn btn-neutral btn-icon btn-action input-group-btn" v-else>
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
      </div>
      <template v-if="!loading">
        <div class="empty" v-if="filteredAppointments.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'calendar-alt']" size="3x"/>
          </div>
          <p class="empty-title h5">Sem agenda</p>
          <p class="empty-subtitle">Não existe configuração de agenda para esta data</p>
        </div>
        <template v-else>
          <table class="table table-hover">
            <tbody>
            <clinical-table-row v-for="(item, i) in filteredAppointments"
                                :data="item"
                                :key="i"
                                @medicalReportHistory="openMedicalReportHistoryModal"
                                @attachment="openAttachmentModal"
            />
            </tbody>
          </table>
        </template>
      </template>
      <div class="loading loading-lg mt-2" v-else></div>
      <dx-modal title="Anexos do paciente"
                id="modal-attachment" size="md"
                v-if="modalAttachment.show"
                v-model="modalAttachment.show">
        <st-entity-file-manager
          :entity-id="modalAttachment.patientId"
          :origin-id="modalAttachment.appointmentId"
          :list-all="modalAttachment.listAllImages"
          origin-type="appointment"
          :readonly="true"
        ></st-entity-file-manager>
        <label class="form-switch mt-2">
          <input type="checkbox" v-model="modalAttachment.listAllImages"/>
          <i class="form-icon"/> Exibir outros anexos deste paciente
        </label>
        <template slot="footer">
          <button class="btn"
                  @click="modalAttachment.show = false">Sair</button>
        </template>
      </dx-modal>
      <medical-report-history-modal
        v-if="modalMedicalReportHistory.show"
        :show="modalMedicalReportHistory.show"
        :patient-id="modalMedicalReportHistory.patientId"
        @close="modalMedicalReportHistory.show = false"
      />
      <information-modal
        v-if="information.show"
        :show="information.show"
        :date="filter.date"
        :schedule-id="filter.scheduleId"
        @close="information.show = false"
      />
    </template>
    <forbidden v-else />
  </div>
</template>

<script>
import moment from 'moment';
import fuzzy from 'fuzzy';
import { mapActions, mapMutations, mapState } from 'vuex';
import AblyService from '@/services/ably';
import formMixin from 'src/mixins/form';
import * as status from 'src/data/appointment-statuses';
import Forbidden from '@/components/auth/Forbidden.vue';
import { LIST_ATTENDANCES } from '@/data/actions/modules/clinical';
import appointmentStatuses, { getColor } from '@/data/appointment-statuses';
import ClinicalTableRow from './ClinicalTableRow.vue';
import InformationModal from './Information.vue';
import MedicalReportHistoryModal from '../../patient/modals/MedicalReportHistory.vue';

export default {
  mixins: [formMixin],
  components: {
    ClinicalTableRow,
    InformationModal,
    Forbidden,
    MedicalReportHistoryModal,
  },
  data() {
    return {
      debounceTimeout: null,
      status,
      search: '',
      filter: this.$store.state.attendance.filter,
      information: {
        show: false,
      },
      modalAttachment: {
        show: false,
        patientId: '',
        appointmentId: '',
        listAllImages: false,
      },
      modalMedicalReportHistory: {
        show: false,
        patientId: '',
        appointmentId: '',
      },
    };
  },
  computed: {
    ...mapState({
      appointments: state => state.attendance.items,
      loading: state => state.attendance.loading,
      user: ({ auth }) => auth.user,
    }),
    filteredAppointments() {
      let items = this.appointments
        .filter(item => item.patient && item.patient.name);

      const search = this.formatFilterSearch();

      if (search) {
        const results = fuzzy.filter(search, items, {
          extract: (el) => {
            if (el.patient && el.patient.name) {
              return this.removeAccentuation(el.patient.name);
            }
            return '';
          },
        });

        items = results.map(({ original }) => original);
      }

      const fakeDate = '3000-01-01T00:00';
      return items.sort((a, b) => {
        if (a.startDate > b.startDate) return 1;
        if (a.startDate < b.startDate) return -1;

        if (!['waiting', 'scheduled'].includes(a.status)) return 1;
        if (!['waiting', 'scheduled'].includes(b.status)) return -1;

        if ((a.arrivedAt || fakeDate) > (b.arrivedAt || fakeDate)) return 1;
        if ((a.arrivedAt || fakeDate) < (b.arrivedAt || fakeDate)) return -1;
        return 0;
      });
    },
    eventsChannel() {
      return `${this.user.branch.id}-appts`;
    },
    dateString() {
      return moment(this.filter.date).format('YYYY-MM-DD');
    },
    canAccess() {
      if (LIST_ATTENDANCES) {
        return this.$can(LIST_ATTENDANCES);
      }
      return true;
    },
    statusColor() {
      return getColor(this.filter.status);
    },
    statuses() {
      return [{
        key: '',
        name: 'Todos',
        color: '#e0e0e0',
      }, ...appointmentStatuses];
    },
  },
  watch: {
    'filter.date': function filterDate() {
      this.loadCalendar();
    },
    'filter.status': function filterStatus() {
      this.loadCalendar();
    },
    'filter.scheduleId': function filterSchedule() {
      this.loadCalendar();
    },
  },
  mounted() {
    if (this.user.branch.settings && !this.user.branch.settings.canViewSchedule) {
      this.filter.professionalId = this.user.id;
    }
    this.loadCalendar();
    this.loadFilters();
    this.startEvents();
  },
  beforeDestroy() {
    this.stopEvents();
  },
  methods: {
    ...mapActions({
      loadCalendar: 'loadAttendanceCalendar',
      loadFilters: 'loadAttendanceFilters',
    }),
    ...mapMutations({
      upsert: 'Attendance.UPSERT',
      remove: 'Attendance.REMOVE',
    }),
    eventListener({ name, data }) {
      if (name === 'delete') {
        this.remove(data);
      } else if (this.dateString === data.startDate.substring(0, 10)) {
        if (this.appointments.length > 0) {
          this.upsert(data);
        } else {
          this.loadCalendar();
        }
      }
    },
    startEvents() {
      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.subscribe(this.eventListener);
    },
    stopEvents() {
      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.unsubscribe(this.eventListener);
    },
    formatFilterSearch() {
      return this.removeAccentuation(this.search);
    },
    openAttachmentModal(data) {
      this.modalAttachment.patientId = data.patientId;
      this.modalAttachment.appointmentId = data.appointmentId;
      this.modalAttachment.listAllImages = false;
      this.modalAttachment.show = true;
    },
    openMedicalReportHistoryModal(data) {
      this.modalMedicalReportHistory.patientId = data.patientId;
      this.modalMedicalReportHistory.appointmentId = data.appointmentId;
      this.modalMedicalReportHistory.show = true;
    },
    setStatus({ key }) {
      this.filter.status = key;
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .clinical-content-page {
    .dropdown {
      & > a {
        display: block;
        margin-top: .05rem;
        &:focus {
          box-shadow: none;
        }
      }
      .tile-content {
        line-height: $line-height;
      }
    }
    table {
      background-color: #fff;
      margin-top: $layout-spacing;
      table-layout: fixed;
      tr {
        td {
          border: .05rem solid $border-color;
        }
      }
    }
  }
</style>
